body {
    margin: 0;
    font-family: 'Switzer-Variable';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

#btn-col {
    background-color: #2F5325;
}